import React, { useEffect, useRef } from 'react';
import { Renderer } from 'interactive-shader-format';

function MainIntAudMap() {
  const canvasRef = useRef(null);

  useEffect(() => {
    async function initRenderer() {
      if (canvasRef.current) {
        const gl = canvasRef.current.getContext('webgl');
        if (!gl) {
          console.error('WebGL not supported');
          return;
        }

        const renderer = new Renderer(gl);

        try {
          // Correct shader path if placed in public/
          await renderer.loadSource('/test.fs');

          // Check if the program has been initialized correctly
          if (!renderer.program) {
            console.error('Shader program failed to initialize.');
            
            // Additional debugging information
            const error = gl.getError();
            console.error('WebGL Error:', error);

            // Debug shader compile and program link logs
            if (renderer.vertexShader) {
              const vertexShaderLog = gl.getShaderInfoLog(renderer.vertexShader);
              if (vertexShaderLog) {
                console.error('Vertex Shader compile log:', vertexShaderLog);
              } else {
                console.error('Vertex Shader info log not available.');
              }
            } else {
              console.error('Vertex Shader object is not available.');
            }

            if (renderer.fragmentShader) {
              const fragmentShaderLog = gl.getShaderInfoLog(renderer.fragmentShader);
              if (fragmentShaderLog) {
                console.error('Fragment Shader compile log:', fragmentShaderLog);
              } else {
                console.error('Fragment Shader info log not available.');
              }
            } else {
              console.error('Fragment Shader object is not available.');
            }

            if (renderer.program) {
              const programLog = gl.getProgramInfoLog(renderer.program);
              if (programLog) {
                console.error('Program link log:', programLog);
              } else {
                console.error('Program link log not available.');
              }
            } else {
              console.error('Program object is not available.');
            }

            return;
          }

          // Start the rendering loop
          function animate() {
            renderer.draw();  // Render the frame
            requestAnimationFrame(animate);
          }
          animate();

        } catch (error) {
          console.error('Error loading or compiling shader:', error);
        }
      }
    }

    initRenderer();

  }, []);

  return (
    <div>
      <canvas ref={canvasRef} width={window.innerWidth} height={window.innerHeight} />
    </div>
  );
}

export default MainIntAudMap;
